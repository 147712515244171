<template>
  <div class="container-fluid px-0">
    <div class="page-content card">
      <div class="row mx-0">
        <div class="col-12 px-0" style="padding-bottom: 80px;">
          <table border="0" width="100%">
            <thead class="thead-fixed">
              <tr class="text-right" style="border-bottom: 0px !important">
                <td class="" colspan="13">
                  <div class="d-flex">
                    <dx-util-select-box
                      id="store"
                      v-model="selectedUser"
                      :data-source="currentCompanyUsers"
                      :search-enabled="true"
                      display-expr="fullName"
                      value-expr="id"
                      @value-changed="getCalendarCompanies"
                    />
                    <dx-util-select-box
                      v-model="companySize" placeholder="Select company size"
                      :data-source="sizes" display-expr="text"
                      value-expr="value" width="200"
                      @value-changed="getCalendarCompanies"
                    />
                    <dx-util-tag-box
                      v-model="selectedDays"
                      placeholder="Select Days"
                      :items="days"
                      class="mx-half" style="min-width: 300px;"
                      :show-selection-controls="true"
                      @value-changed="onUpdateSelectedDays"
                    />
                    <div class="flex-grow-1" />
                    <dx-util-button text="Task Management" type="success" class="mx-half" @click="goToTaskManagement" />
                    <dx-util-button v-if="false" text="Generate All Tasks" type="default" class="mx-half" @click="generateAllTasks" />
                  </div>
                </td>
              </tr>
              <tr style="border-bottom: 0px !important">
                <th>Customer</th>
                <th>Store Name</th>
                <th>Monthly Volume</th>
                <th>Account Manager</th>
                <th v-for="day in selectedDays" :key="day">
                  {{ day }}
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <calendar-row v-for="company in companies" :key="company.id"
                :row="company" :selected-days="selectedDays"
                :company-users="currentCompanyUsers"
                @open-company-details="openCompanyDetails"
                @row-updated="getCalendarCompanies"
              />
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="page-size-container">
      <div class="d-flex">
        <div v-for="size in pageSizes" :key="size" class="py-1">
          <span :class="pageSize === size ? 'page-size-color' : ''"
              role="button" style="padding-inline: 14px; padding-block: 6px; margin-inline: 4px; border-radius: 2px;"
              @click="pageSize = size"
          >
            {{ size }}
          </span>
        </div>
        <div class="flex-grow-1"></div>
        <div class="py-1 chevrons" style="margin-right: 4px;">
          <span class="" role="button" @click="gotoPreviousPage">
            <p-icon name="bi-chevron-left" />
          </span>
        </div>
        <div v-for="pageNo in totalPages" :key="pageNo" class="py-1">
          <span :class="pageNo === selectedPageNo ? 'page-size-color' : ''"
              role="button" style="padding: 8px; margin-inline: 2px; border-radius: 2px;"
              @click="selectedPageNo = pageNo"
          >
            {{ pageNo }}
          </span>
        </div>
        <div class="py-1 chevrons" style="margin-right: 4px;">
          <span role="button" @click="gotoNextPage">
            <p-icon name="bi-chevron-right" />
          </span>
        </div>
      </div>
    </div>
    <company-edit :component-id="companyEditCompId" :company-id="selectedCompanyId" @update-company="getCalendarCompanies" />
  </div>
</template>

<script>
//  import companyService from '@/http/requests/company/companyService'
import CalendarSearchFilter from '@/http/models/search-filters/CalendarSearchFilter'
import { getTaskTypesEnumList } from '@/enums/taskType.enum'
import { getStatusEnumList } from '@/enums/taskStatus.enum'
import { TaskPriorityEnum, TaskStatusEnum, TaskTypesEnum } from '@/enums'
import { getPriorityEnumList } from '@/enums/taskPriority.enum'
import { getCompanySizeList } from '@/enums/companySize.enum'
import { v4 as uuidv4 } from 'uuid'
// import companyService from '@/http/requests/company/companyService'
import userManagementService from '@/http/requests/system/userManagementService'
import UserSearchFilter from '@/http/models/search-filters/UserSearchFilter'
import Pager from '@/@core/dev-extreme/utils/pager'
import useCurrentUser from '@/libs/app/current-user'
import companyService from '@/http/requests/company/companyService'
import useTaskManagementState from './useTaskManagementState.js'

export default {
  components: {
    'company-edit': () => import('../company/company-list/components/CompanyEdit.vue'),
    'calendar-row': () => import('./components/CalendarRow.vue'),
  },
  data() {
    return {
      days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      sizes: getCompanySizeList(),
      companySize: 0,
      companyEditCompId: '',
      companies: [],
      companyList: Array.from({ length: 100 }, () => ({
        id: uuidv4(),
        accountNo: '',
        suiteNo: '',
        companyId: 1500,
        companyName: 'DPC-House',
        storeName: 'My Best Shoes',
        volume: '10000+',
        accountManager: 'Manager1',
        days: {
          Monday: [],
          Tuesday: [],
          Wednesday: [],
          Thursday: [],
          Friday: [],
          Saturday: [],
          Sunday: [],
        },
      })),
      selectedCompanyId: 0,
      pageSize: 25,
      pageSizes: [25, 50, 100, 200, 250],
      totalPages: 1,
      selectedPageNo: 1,
      // old
      taskTypes: getTaskTypesEnumList(),
      taskStatus: getStatusEnumList(),
      taskPriorities: getPriorityEnumList(),
      selectedUser: 0,
      currentCompanyUsers: [],
      addTaskCompId: '',
      filter: {
        beginDate: '',
        endDate: '',
        taskType: TaskTypesEnum.ALL.value,
        taskStatus: TaskStatusEnum.OPEN.value,
        priority: TaskPriorityEnum.ALL.value,
        query: '',
      },
    }
  },
  setup() {
    const { userRoleName, userCompanyId } = useCurrentUser()
    const { selectedDays } = useTaskManagementState()

    return {
      userRoleName, selectedDays, userCompanyId,
    }
  },
  computed: {
    dataGrid() {
      const grid = this.$refs.tasksGridRef.instance
      return grid
    },
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
    hasClientPermission() {
      return this.$can('read', 'resource_clients_companies')
    },
    queryString() {
      return `page=${this.selectedPageNo - 1}&size=${this.pageSize}`
    },
  },
  watch: {
    queryString() {
      this.getCalendarCompanies()
    },
  },
  mounted() {
    this.getUsersList(this.userCompanyId)
    this.getCalendarCompanies()
  },
  methods: {
    goToTaskManagement() {
      this.$router.push({ name: 'route-task-management' })
    },
    async generateAllTasks() {
      const payload = {}
      await companyService.generateTasks(payload)
    },
    onUpdateSelectedDays(e) {
      const dayIndexMap = new Map()
      this.days.forEach((day, index) => dayIndexMap.set(day, index))
      this.selectedDays.sort((day1, day2) => dayIndexMap.get(day1) - dayIndexMap.get(day2))
      this.getCalendarCompanies()
    },
    openCompanyDetails(company) {
      this.companyEditCompId = uuidv4()
      this.selectedCompanyId = company.companyId
    },
    getUsersList(companyId) {
      UserSearchFilter.setDefaultFilters()
      UserSearchFilter.companyId = companyId
      UserSearchFilter.activated = true
      const filters = UserSearchFilter.getFilters()
      const pager = new Pager()
      userManagementService
        .getUserInfoBasicByQuery(filters, pager.staticPageable)
        .then(response => {
          const data = response.data
          this.currentCompanyUsers = data.content
          this.currentCompanyUsers.unshift({
            fullName: 'All Users', id: 0,
          })
        })
    },
    async getCalendarCompanies() {
      const today = new Date()
      const sunday = new Date(today)
      sunday.setDate(sunday.getDate() + (7 - today.getDay()))

      CalendarSearchFilter.workingDays = this.selectedDays
      CalendarSearchFilter.monthlyItemVolume = this.companySize
      CalendarSearchFilter.assignTo = this.selectedUser
      CalendarSearchFilter.beginDate = today
      CalendarSearchFilter.endDate = sunday
      const taskCalendarFilter = CalendarSearchFilter.getFilters()

      const response = await companyService.getCalendarPageQuery(taskCalendarFilter, this.queryString)
      this.companies = response.content
      this.totalPages = response.totalPages
      this.pageSize = response.size
    },
    gotoNextPage() {
      if (this.selectedPageNo === this.totalPages) {
        this.selectedPageNo = this.totalPages
        return
      }
      this.selectedPageNo += 1
    },
    gotoPreviousPage() {
      if (this.selectedPageNo === 1) {
        this.selectedPageNo = 1
        return
      }
      this.selectedPageNo -= 1
    },
  },

}
</script>

<style lang="scss" scoped>
th, td {
  padding: 10px;
}

.dark-layout thead {
  border-bottom: #161D31 1px solid;
}

.light-layout thead {
  border-bottom: #bccdfd 1px solid;
}

.bordered-layout thead {
  border-bottom: #bccdfd 1px solid;
}

th {
  color: orange;
}

table {
  border-collapse: collapse;
}

.dark-layout  tr {
  border-bottom: 1px solid rgb(54, 70, 113);
}

.light-layout  tr {
  border-bottom: 0.5px solid rgb(146, 147, 148);
}

.bordered-layout  tr {
  border-bottom: 0.5px solid rgb(146, 147, 148);
}

.dark-layout .page-size-color {
  background-color: #49567D;
}

.light-layout .page-size-color {
  background-color: #CFCFCF;
}

.bordered-layout .page-size-color {
  background-color: #CFCFCF;
}

.page-content {
  min-height: calc(100vh - 200px); /* Adjust height as needed */
}

.page-size-container {
  position: fixed;
  bottom: 30px;
  left: 0;
  width: 100%;
  padding: 10px;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better visibility */
}
.dark-layout .page-size-container {
  background-color: #283046;
}

.light-layout .page-size-container {
  background-color: #F8F8F8;
}

.bordered-layout .page-size-container {
  background-color: #F8F8F8;
}

.thead-fixed {
  position: sticky;
  top: 48px;
  z-index: 1;
}

.dark-layout .thead-fixed {
  background-color: #283046;
}

.bordered-layout .thead-fixed {
  background-color: #F8F8F8;
}
.light-layout .thead-fixed {
  background-color: #F8F8F8;
}

</style>
