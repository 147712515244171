import { ref } from '@vue/composition-api'

const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
const selectedDays = ref([weekdays[new Date().getDay()]])

export default function useTaskManagementState() {
  return {
    selectedDays,
  }
}
