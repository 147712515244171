/* eslint-disable lines-between-class-members */
import BaseSearchFilter from './BaseSearchFilter'

export class CalendarSearchFilter extends BaseSearchFilter {
  /** @type {?number} */
  #assignTo = 0
  /** @returns {?number} */
  get assignTo() {
    return this.#assignTo
  }
  /** @param {?number} value */
  set assignTo(value) {
    this.#assignTo = value
  }

  /** @type {?number} */
  #monthlyItemVolume = 0
  /** @returns {?number} */
  get monthlyItemVolume() {
    return this.#monthlyItemVolume
  }
  /** @param {?number} value */
  set monthlyItemVolume(value) {
    this.#monthlyItemVolume = value
  }

  /** @type {!string} */
  #workingDays = ''
  /** @returns {string} */
  get workingDays() {
    return this.#workingDays
  }
  /** @param {!string} value */
  set workingDays(value) {
    this.#workingDays = value
  }

  /** @type {Array} */
  #selectedCompanyIds = []
  /** @returns {Array} */
  get selectedCompanyIds() {
    return this.#selectedCompanyIds
  }
  /** @param {Array} value */
  set selectedCompanyIds(value) {
    this.#selectedCompanyIds = value
  }

  constructor() {
    super()
    this.#assignTo = this.assignTo
    this.#workingDays = this.workingDays
    this.#selectedCompanyIds = this.selectedCompanyIds
    this.#monthlyItemVolume = this.monthlyItemVolume
  }

  setDefaultFilters() {
    this.accountNo = ''
    this.companyId = 0
    this.storeId = 0
    this.warehouseId = 0
    this.beginDate = null
    this.endDate = null
    this.tenantType = 'SERVING'
    this.q = ''
    this.assignTo = null
    this.workingDays = ''
    this.selectedCompanyIds = []
    this.monthlyItemVolume = 0
  }

  /**
   * The complete PlanSearchFilter get filters.
   * @typedef {Object} filters
   * @property {number} accountNo - Account Number.
   * @property {number} companyId - Company Id.
   * @property {number} storeId - Store Id.
   * @property {number} warehouseId - Store Id.
   * @property {any} beginDate - Begin date.
   * @property {any} endDate - End date.
   * @property {string} tenantType - Tenant Type.
   * @property {string} q - Query.
   * @property {string} assignTo - Assigned Staff.
   * @property {string} workingDays - Working days for customer.
   * @property {Array} selectedCompanyIds - Company IDs.
   * @property {number} monthlyItemVolume - Company shipment items' total.
   */

  /** @returns {filters} {@link filters} objects */
  getFilters() {
    const filter = {
      accountNo: this.accountNo,
      companyId: this.companyId,
      storeId: this.storeId,
      warehouseId: this.warehouseId,
      beginDate: this.beginDate,
      endDate: this.endDate,
      tenantType: this.tenantType,
      q: this.q,
      assignTo: this.assignTo,
      workingDays: this.workingDays,
      selectedCompanyIds: this.selectedCompanyIds,
      monthlyItemVolume: this.monthlyItemVolume,
    }
    return filter
  }
}
export default new CalendarSearchFilter()
